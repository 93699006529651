import React from 'react'
import { graphql, Link, navigate, useStaticQuery } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'
import { GatsbyImage } from "gatsby-plugin-image";

export default function BlogPageTemplate({ data, pageContext, ...props }) {
  //   const post = data.mdx
  const { current, start, end, next, total } = pageContext
  const posts = data.allMdx.edges.map(({ node }) => node)
  const images = data.allFile.edges.map(({ node }) => node)

  return (
    <Layout>
      <Seo
        title={`Blog Posts page ${current} of ${total}`}
        description={`List of blog posts from Geoffrey Hunt, page ${current} of ${total}`}
        type="page"
        path={current===1?`/blog`:`/blog/${current}`}
      />

      <div className="bg-light pt-8 pb-5 pr-5 pl-5 md:pr-10 md:pl-10 mt-4 mb-4 max-w-full rounded-md prose">
        <h1 className="mb-2">{`Blog Posts (page ${current} of ${total})`}</h1>
        {posts.map(node => (
          <div key={node.id} className="flex flex-wrap md:flex-nowrap mt-6">
            <div className="flex-none cursor-pointer" onClick={()=>navigate(`/blog/${node.slug}`)} style={{ width: '180px' }}>
              <GatsbyImage
              imgClassName="mt-0 mb-0"
                image={images.find(
                  a => `${a.name}${a.ext}` === node.frontmatter.featured_image
                )?.childImageSharp?.gatsbyImageData}
                alt={`${node.frontmatter.title}`} />
            </div>
            <div className="ml-4  flex-grow">
              <h2 className="text-3xl mt-0 mb-2">{node.frontmatter.title}</h2>
              <p className="text-sm m-0">Posted: {node.frontmatter.date}</p>
              <p className="m-0">{node.excerpt}</p>

              <Link
                to={`/blog/${node.slug}`}
                className="text-lg underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light mr-2"
              >
                read more
              </Link>
            </div>
          </div>
        ))}
        <div className="flex mt-4">
          {current !== 1 && (
            <Link
              to={`/blog/${current - 2 === 0 ? '' : current - 2}`}
              rel="prev"
              className="text-lg underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light mr-2"
            >
              <button className="py-2 px-4 bg-bright text-white font-semibold rounded-lg shadow-md hover:bg-dark text-base">
                previous
              </button>
            </Link>
          )}
          {next !== total && (
            <Link
              to={`/blog/${next}/`}
              rel="next"
              className="text-lg underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light"
            >
              <button className="py-2 px-4 bg-bright text-white font-semibold rounded-lg shadow-md hover:bg-dark text-base">
                next
              </button>
            </Link>
          )}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`query ($start: Int, $images: [String]) {
  allFile(filter: {name: {in: $images}, dir: {glob: "**/posts/images/**"}, ext: {glob: "{.jpg,.jpeg,.png}"}}) {
    edges {
      node {
        relativePath
        name
        ext
        childImageSharp {
          gatsbyImageData(
            width: 180
            height: 160
            transformOptions: {cropFocus: CENTER}
            layout: FIXED
          )
        }
      }
    }
  }
  allMdx(
    sort: {fields: frontmatter___date, order: DESC}
    filter: {fileAbsolutePath: {glob: "**/posts/**{.md,.mdx}"}}
    limit: 10
    skip: $start
  ) {
    edges {
      node {
        excerpt
        id
        frontmatter {
          title
          description
          layout
          title
          categories
          featured_image
          date(formatString: "MMMM DD, YYYY")
        }
        slug
      }
    }
  }
}
`
